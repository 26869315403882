html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: system-ui, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
}

/* CSS */
.button {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  margin: 0;
  min-height: 1.75rem;
  padding: calc(0.25rem - 1px) calc(0.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.button:hover,
.button:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.button:hover {
  transform: translateY(-1px);
}

.button:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

/* Common stuff for things that change with aspect ratio: */
.App {
  display: flex;
  justify-items: center;
  align-items: center;
}
.field {
  display: grid;
  align-self: center;
  border: solid #0008 2pt;
}
.inactive {
  color: gray;
}
.header {
  text-align: center;
  font-weight: bold;
}
.label {
  align-self: flex-end;
  text-align: right;
  font-weight: bold;
  font-size: larger;
}
.score {
  text-align: center;
}
.redscore,
.red .terminal.score {
  color: red;
}
.bluescore,
.blue .terminal.score {
  color: blue;
}
.redscore.cones,
.bluescore.cones {
  letter-spacing: -3pt;
  font-size: smaller;
}
.stats {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  justify-items: center;
}
#button-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5pt;
  align-items: stretch;
}
#scoreboard {
  display: grid;
  border: solid #eee 1px;
  padding: 3px;
  gap: 5pt;
  justify-content: space-around;
  margin: 5pt;
}
#coneboard {
  display: grid;
  border: solid #eee 1px;
  padding: 3px;
  gap: 5pt;
  justify-content: space-around;
  margin: 5pt;
}
/* To lay stuff out better, dynamically, check out: https://developer.mozilla.org/en-US/docs/Web/CSS/@media/aspect-ratio */
@media (max-aspect-ratio: 7 / 8) {
  /* Portrait mode layout */
  .App {
    flex-direction: column;
  }
  .field {
    margin-top: 0.5vw;
    grid-template-columns: 9vw 16vw 16vw 16vw 16vw 16vw 9vw;
    grid-template-rows: 9vw 16vw 16vw 16vw 16vw 16vw 9vw;
  }
  .stats {
    flex-direction: row;
    width: 100%;
  }
  #scoreboard {
    grid-template-columns: auto 1fr 1fr;
    grid-template-rows: auto auto auto;
  }
  #coneboard {
    grid-template-columns: 1fr 0.5fr 0.5fr;
    grid-template-rows: auto auto auto auto;
  }
}
@media (min-aspect-ratio: 7 / 8) {
  /* Portrait mode layout */
  .App {
    flex-direction: column;
  }
  .field {
    margin-top: 0.5vh;
    grid-template-columns: 6vh 14vh 14vh 14vh 14vh 14vh 6vh;
    grid-template-rows: 6vh 14vh 14vh 14vh 14vh 14vh 6vh;
  }
  .stats {
    flex-direction: row;
    width: 100%;
  }
  #scoreboard {
    grid-template-columns: auto 1fr 1fr;
    grid-template-rows: auto auto auto;
  }
  #coneboard {
    grid-template-columns: 1fr 0.5fr 0.5fr;
    grid-template-rows: auto auto auto auto;
  }
}
/* TODO: Get a layout that works for the 'in between' aspect ratio */
@media (min-aspect-ratio: 20 / 17) {
  /* Landscape mode layout */
  .App {
    flex-direction: row;
  }
  .field {
    margin-top: 1vh;
    margin-left: 1vh;
    grid-template-columns: 9vh 16vh 16vh 16vh 16vh 16vh 9vh;
    grid-template-rows: 9vh 16vh 16vh 16vh 16vh 16vh 9vh;
  }
  .stats {
    flex-direction: column;
    height: 100%;
  }
  #scoreboard {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto auto;
  }
  #scoreboard .label {
    grid-column: 1/3;
    text-align: center;
    font-size: larger;
  }
  #coneboard {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto auto auto auto auto auto;
  }
  #coneboard .label {
    grid-column: 1/3;
    text-align: center;
    font-size: larger;
  }
}
